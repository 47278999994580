<template>
    <div class="sub-nav">
        <div v-for="(item,index) in nav" :key="index" :class="{'active':router?item.value==$route.path:item.value==active}" @click="handler(item.value)">{{item.name}}</div>
    </div>
</template>

<script>
export default {
  name: 'SubNav',
  props:{
    nav:{
      type:Array,
      default:()=>{
        return []
      }
    },
    router:{
      type:Boolean,
      default:false
    },
    defaultActive:[String,Number]
  },
  data() {
    return {
      active:''
    }
  },
  methods:{
    handler(value){
      this.active=value
      if(this.router){
        this.jump(value)
      }else{
        this.$emit('change',value)
      }
    },
    jump: function(path){
      if(path == this.$route.path){
        this.$router.replace({
              path: '/redirect',
              query: {path}
          })
      }else {
          this.$router.push({
          path: path
          })
      }
    },
  },
  created(){
    this.active=this.defaultActive
  },
  mounted(){
    
  },
  destroyed(){
   
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.sub-nav{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 0 20px;
    height: 30px;
    flex:0 0 30px;
    white-space: nowrap;
    background: #004ea3;
    
  }
  .sub-nav>div{
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    margin-right: 20px;
    color: #fff;
    border-radius: 5px 5px 0 0/5px 5px 0 0;
    cursor: pointer;
  }
  .sub-nav>div:hover{
    color: #cfe6ff;
  }
  .sub-nav>div.active{
    font-weight: bold;
    color: #4d85d9;
    @include themeify{background:themed('background');};
  }
  html[data-theme=dark] .sub-nav{
    background: #1a2031;
  }
</style>
